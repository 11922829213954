import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../components/layout'
// import Image from '../components/image'
import SEO from '../components/seo'
// import { FaBeer } from 'react-icons/fa'
import img from '../images/aboutimg.jpg'
import { PageHeader, Banner } from '../utils/'
import AboutInfo from '../components/AboutComponents/AboutInfo'
import Certs from '../components/ServicesComponents/Certs'

const AboutPage = () => (
  <Layout>
    <SEO
      title='About S.A. Walley Marine Surveying'
      keywords={[
        `Marine Surveying`,
        `Gulf Coast`,
        `Walley`,
        `Pensacola`,
        `Florida`,
        `Orange Beach`,
        `Gulf Shores`,
        `Alabama`,
        `Perdido Key`,
        `Northwest Florida`,
        `Mississippi`,
        `Louisiana`,
        `Tennessee`,
        `Constulting`,
        `Delivery`,
        `Vessel`,
        `Boat`,
        `Evaluation`,
        `Insurance Claim`,
        `Valuation`
      ]}
    />
    <PageHeader img={img}>
      <Banner title='about' subtitle='S.A. Walley Marine Services' />
    </PageHeader>
    <AboutInfo />
    <Certs />
  </Layout>
)

export default AboutPage
