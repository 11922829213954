import React, { Component } from 'react'
import styled from 'styled-components'
import { styles } from '../../utils'
import logo from '../../images/wms-nav.png'

export default class AboutInfo extends Component {
  render() {
    return (
      <div className='container'>
        <AboutWrapper>
          <div className='about'>
            <center>
              <img src={logo} alt='WMS logo' />
              <br />
              <p>
                I have always had a passion for boats and the sea. I have been a
                licensed captain for most of my life. My passion began as a
                young guy growing up around the water and admiring all of the
                boats and the people who owned them. The passion extends from
                the small recreational boats to the large fishing vessels. After
                serving as a self proprietor in the construction field serving a
                large clientel for over 25 years, I finally have gotten the
                chance to serve the marine community. As a long-term long
                distance cruiser for the past decade, I can relate to the
                importance of having and maintaining a sound vessel. My goal in
                the marine surveying business is to serve my clients to the best
                of my ability. Whether it’s a pre-purchase situation or an
                insurance renewal, I want them to have the confidence that their
                vessel is prepared to serve them in whatever manor that they
                wish.
              </p>
            </center>
          </div>
        </AboutWrapper>
      </div>
    )
  }
}

const AboutWrapper = styled.div`
  width: 100%;
  margin: 2rem auto;
  .h1 {
    margin-bottom: 1rem;
    margin-top: 2rem;
    color: ${styles.colors.Navy};
  }
  .text {
    line-height: 2rem;
    word-spacing: 0.2rem;
  }
`
